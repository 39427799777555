.board {
	/* width: 400px;
    height: 400px; */
	background-color: #457b9d;
	background-color: #b0a092;
	/* border: 2px groove #E63946 */
	/* border: 2px groove #F1FAEE; */

	border-radius: 5%;
	margin: 0.5rem 0rem 0rem;
}

.temp {
	color: #3f3225;
	color: #5e564f;
	color: #a18e80;
	color: #b0a092;
}

.board__container {
	display: flex;
	align-items: center;
}

.board__player-name {
	font-size: medium;
	margin-left: 0.3rem;
}

.board__walls-left {
	font-size: medium;
	/* margin-left: 0.3rem; */
	margin-top: 0.3rem;
	/* margin: 10rem 0; */
}

.board__sidebar {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: 3rem;
}

.board__player-box {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 0rem 3rem;
	/* margin-left: 3rem; */
	/* border: 1px groove white; */
	padding: 1rem;
	/* width: 20%; */
	/* background: rgba(0,0,0,.2) */
	color: #bfbeba;
}

.board__person-icon {
	/* color:white */
}

.board__wall-icon {
	color: white;
	margin-right: 0.7rem;
	margin-top: 0.2rem;
	margin-left: 0.3rem;
}

.board__winner-text {
	font-size: 24px;
	color: #f3c536 !important;
	margin-left: 1.7rem;
}

.board__resigned-text {
	font-size: 16px;
	margin-left: 1.7rem;
}

.board__chat-box {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	border: 1px groove white;
	padding: 0.8rem;
	padding-bottom: 0;
	height: 300px;
	overflow: auto;
	max-width: 100%;
	margin-bottom: 5rem;
	background: rgba(0,0,0,.2);
	max-width: 200px;
}

.board__chat {
	margin: 0.1rem;
	font: initial;
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif;
	text-align: initial;
}

.board__chat-messages {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

}

.board__chat-sender {
	font-size: small;
	font-weight: 800;
	color: #f3c536;
}

.board__chat-payload {
	font-size: small;
}

.board__chat-input {
	margin-top: 0.5rem;
	margin-bottom: 0 !important;
}

.board__chat-couter-wrapper {
	overflow: auto;
	display: flex;
	flex-direction: column-reverse;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.board__chat-couter-wrapper::-webkit-scrollbar {
	display: none;
  }

.board__button-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.board__sidebar-button {
	width: 70%;
	margin-bottom: 0.5rem !important;
}
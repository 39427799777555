.lobby__title {
	margin-bottom: 0rem;
}

.lobby__person {
	display: flex;
	align-items: center;
	margin: 1rem;
}

.lobby__person-name {
	margin-left: 0.2rem;
}

.lobby__person--free {
	opacity: 0.2;
	font-style: italic;
}

.lobby__room-container {
	display: flex;
	height: 5rem;
	width: 20rem;
	/* border: 1px solid white; */
}

.lobby__rooms-container {
	display: grid;
	/* grid-template-columns: repeat(4,1fr); */
	grid-template-columns: repeat(5, 1fr);
	/* border: 1px solid white; */
}

.lobby__vs {
	font-weight: 800;
	align-self: center;
}

.lobby__join-button {
	height: 50%;
	align-self: center;
	margin-left: 1rem;
}

.lobby__button-container {
	display: flex;
	justify-content: center;
	margin-top: 1rem;
}

.lobby__button {
	margin: 0rem 0.5rem;
}

.landing-page {
    display: flex;
    align-items: center;
}

.landing-page__title {
    font-family: 'Georgia';
    margin-top: -4rem;
}

.landing-page__freeboard {
    margin-right: 5rem;
}

@media screen and (max-width: 768px)  {
    .landing-page__freeboard {
        display: none;
    }
}

.landing-page__right {
    display: flex;
    flex-direction: column;
}

.landing-page__nickname {
    align-self: center;
    width: 70%;
}

.landing-page__button {
    margin: 1rem 0rem !important;
    align-self: center;
    width: 30%;
}

.landing-page__facebook {
    transition: all;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 2rem;
    right: 4rem
}

.landing-page__facebook:hover {
    transform: scale(1.2)
}